import React, { useState, useEffect } from "react"
import CasesTable from "./components/CasesTable/CasesTable"
import { updateCaseInfo } from "../../services/notebook/notebookServices"
import { useSelector, useDispatch } from "react-redux"
import {
    Typography,
    Button,
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
} from "@mui/material"
import { Lock as LockIcon } from "@mui/icons-material"
import Sidebar from "../../components/SideBar/Sidebar"
import NewAlert from "./components/NewAlert"
import EmptyBox from "./components/EmptyBox"
import plusIcon from "../../assets/icons/MainPage/plusIcon.svg"
import plusIconDisabled from "../../assets/icons/MainPage/plusIconDisabled.svg"
import { useOutletContext } from "react-router-dom"
import styles from "./MainPage.module.scss"
import CaseReportDialog from "./components/CaseReportDialog"

const urgencyOptions = ["Low", "Medium", "High"]

/** Dialog for selecting which report tier the user wants.
 *  We map:
 *    Conclusive => 1
 *    Informative => 2
 *    Proactive => 3
 */
const SelectReportTypeDialog = ({ open, onClose, onSelect }) => {
    // Example logic: userHasUnlockedProactive is false unless >= 100 orders
    const userHasUnlockedProactive = false

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Select report type:</DialogTitle>
            <DialogContent sx={{ minWidth: 300 }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        mt: 1,
                    }}
                >
                    {/* Informative => numeric 2 */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        <Button
                            variant="outlined"
                            className={styles.mainPageHeaderButtonDDTypeMenu}
                            onClick={() => onSelect(2)} // Informative => 2
                        >
                            Informative
                        </Button>
                        <Typography variant="body2" sx={{ ml: 1, mb: 2, marginBottom: 0 }}>
                            All the essential data collected
                        </Typography>
                    </Box>

                    {/* Conclusive => numeric 1 */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        <Button
                            variant="outlined"
                            className={styles.mainPageHeaderButtonDDTypeMenu}
                            onClick={() => onSelect(1)} // Conclusive => 1
                        >
                            Conclusive
                        </Button>
                        <Typography variant="body2" sx={{ ml: 1, mb: 2, marginBottom: 0 }}>
                            Enriched with analysis and insights
                        </Typography>
                    </Box>

                    {/* Proactive => numeric 3 */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        <Button
                            variant="outlined"
                            className={styles.mainPageHeaderButtonDDTypeMenu}
                            disabled={!userHasUnlockedProactive}
                            onClick={() => onSelect(3)} // Proactive => 3
                        >
                            Proactive
                        </Button>
                        <Typography variant="body2" sx={{ ml: 1, mb: 2, marginBottom: 0 }}>
                            Plans for proactive risk mitigations
                        </Typography>

                        {!userHasUnlockedProactive && (
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mb: 2, margin: "5px 0", display: "flex" }}
                            >
                                <LockIcon
                                    sx={{ width: "0.8em", height: "0.8em", margin: "0 5px" }}
                                />{" "}
                                Unlock by ordering 100 reports
                            </Typography>
                        )}
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        See our{" "}
                        <Link
                            href="https://redstrings.io/pricing"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: "#00000099" }}
                        >
                            pricing page
                        </Link>{" "}
                        for more information and prices of the different report types.
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

const ComplianceMainPage = () => {
    const [
        control,
        values,
        triggerFetchMainPageData,
        setTriggerFetchMainPageData,
        loadMainPageData,
        caseTypes,
        isInitialLoading,
    ] = useOutletContext()
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const currentCompanyId = useSelector((state) => state.companiesState.userCompanyId)

    // Toggles for dialogs
    const [openNewAlertPopup, setOpenNewAlertPopup] = useState(false)
    const [reportTypeDialogOpen, setReportTypeDialogOpen] = useState(false)

    // We'll store the numeric code (1,2,3) that user selected
    const [selectedReportType, setSelectedReportType] = useState(null)

    const [welcomeState, setWelcomeState] = useState(false)
    const [sections, setSections] = useState([
        {
            cases: values?.in_progress || [],
            headerTitle: "In progress",
            emptyText: "No pending reports",
            controllerName: "in_progress",
            done: false,
        },
        {
            cases: values?.closed || [],
            headerTitle: "Done",
            emptyText: "No completed reports yet",
            controllerName: "done",
            done: true,
        },
    ])

    const [displayedReportId, setDisplayedReportId] = useState(null)
    const [caseReportDialogOpen, setCaseReportDialogOpen] = useState(false)
    const [auditTrailPreview, setAuditTrailPreview] = useState("<div/>")
    const [successEmailOpen, setSuccessEmailOpen] = useState(false)
    const [failEmailOpen, setFailEmailOpen] = useState(false)

    const openReportDialogById = (id) => {
        setDisplayedReportId(id)
        setCaseReportDialogOpen(true)
    }

    const getWelcomeSubText = () => {
        if (currentCompanyId === 3) {
            return (
                <>
                    Login to{" "}
                    <a href="https://login.salesforce.com/" target="_blank" rel="noreferrer">
                        Saleforce
                    </a>{" "}
                    and get your cases{" "}
                </>
            )
        } else {
            return "Create a new EDD request and get your report"
        }
    }

    const updateCaseInfoMap = async (case_id, type, value) => {
        await updateCaseInfo(case_id, type, value, dispatch, refreshAccessTokenCounter)
    }

    useEffect(() => {
        if (values?.in_progress && values?.done) {
            if (
                !isInitialLoading &&
                values?.in_progress.length === 0 &&
                values?.done.length === 0
            ) {
                setWelcomeState(true)
            } else {
                setWelcomeState(false)
            }
        }

        setSections((prevSections) => [
            {
                ...prevSections[0],
                cases: values?.in_progress || [],
            },
            {
                ...prevSections[1],
                cases: values?.done || [],
            },
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.new_cases, values?.in_progress, values?.closed, isInitialLoading])

    function getDoneItemById(values, id) {
        if (!values || !values.done || !Array.isArray(values.done)) {
            throw new Error("Invalid input: 'values.done' should be an array.")
        }
        return values.done.find((item) => item.id === id)?.completed_report || null
    }

    return (
        <Box className={styles.mainPageContainer}>
            {/* Existing EDD report dialog */}
            {caseReportDialogOpen && (
                <CaseReportDialog
                    open={caseReportDialogOpen}
                    setClosed={() => setCaseReportDialogOpen(false)}
                    caseTitle={values.title}
                    auditTrailPreview={auditTrailPreview}
                    reportData={getDoneItemById(values, displayedReportId)}
                    control={control}
                    successEmailOpen={successEmailOpen}
                    setSuccessEmailOpen={setSuccessEmailOpen}
                    failEmailOpen={failEmailOpen}
                    setFailEmailOpen={setFailEmailOpen}
                    displayedReportId={displayedReportId}
                />
            )}

            {/* "New EDD" dialog, passing the selected numeric code (1,2,3) */}
            <NewAlert
                openNewAlertPopup={openNewAlertPopup}
                setOpenNewAlertPopup={setOpenNewAlertPopup}
                triggerFetchMainPageData={triggerFetchMainPageData}
                setTriggerFetchMainPageData={setTriggerFetchMainPageData}
                updateCaseInfoMap={updateCaseInfoMap}
                loadMainPageData={loadMainPageData}
                caseTypes={caseTypes}
                selectedReportType={selectedReportType}
            />

            <Sidebar />

            {/* The "Select Report Type" dialog */}
            <SelectReportTypeDialog
                open={reportTypeDialogOpen}
                onClose={() => setReportTypeDialogOpen(false)}
                onSelect={(numericCode) => {
                    // numericCode => 1 (Conclusive), 2 (Informative), or 3 (Proactive)
                    setSelectedReportType(numericCode)
                    setReportTypeDialogOpen(false)
                    setOpenNewAlertPopup(true)
                }}
            />

            <Box className={styles.mainPageHeaderWrapper}>
                <Typography fontSize="24px" color="#000">
                    EDD Reports
                </Typography>

                <Button
                    style={{ color: isInitialLoading ? "#e0e0e0" : "#fff" }}
                    disabled={isInitialLoading}
                    className={styles.mainPageHeaderButton}
                    onClick={() => setReportTypeDialogOpen(true)}
                >
                    <img src={isInitialLoading ? plusIconDisabled : plusIcon} alt="+" />
                    Order New Report
                </Button>
            </Box>

            {isInitialLoading ? (
                <Box className={styles.mainPageLoader}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {welcomeState && !isInitialLoading ? (
                        <EmptyBox mainText="Welcome to Redstrings!" subText={getWelcomeSubText()} />
                    ) : (
                        <Box className={styles.mainPageTableWrapper}>
                            {sections.map(
                                (
                                    { cases, headerTitle, emptyText, controllerName, done },
                                    index,
                                ) => (
                                    <React.Fragment key={`${headerTitle}${index}`}>
                                        {cases && cases.length > 0 ? (
                                            <CasesTable
                                                values={values}
                                                triggerFetchMainPageData={triggerFetchMainPageData}
                                                setTriggerFetchMainPageData={
                                                    setTriggerFetchMainPageData
                                                }
                                                control={control}
                                                updateCaseInfoMap={updateCaseInfoMap}
                                                urgencyOptions={urgencyOptions}
                                                loadMainPageData={loadMainPageData}
                                                cases={cases}
                                                headerTitle={headerTitle}
                                                controllerName={controllerName}
                                                done={done}
                                                openReportDialogById={openReportDialogById}
                                            />
                                        ) : (
                                            <EmptyBox subText={emptyText} />
                                        )}
                                    </React.Fragment>
                                ),
                            )}
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}

export default ComplianceMainPage
