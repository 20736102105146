import React, { useEffect, useState } from "react"
import { uuidv4 } from "../../../helpers/standardHelpers"
import makeStyles from "@mui/styles/makeStyles"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import { Box, Button, Tooltip, Switch } from "@mui/material"
import { Controller, useFieldArray } from "react-hook-form"
import { uploadToS3 } from "../../../hooks/standard/uploadFile"
import { useSelector, useDispatch } from "react-redux"
import Autocomplete from "@mui/material/Autocomplete"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"

const useStyles = makeStyles(() => ({
    input: {
        height: 42,
        maxWidth: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
        },
    },
    textArea: {
        maxWidth: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
        },
    },
    label: {
        fontSize: "20px",
        color: "#000",
    },
    addButton: {
        textTransform: "none",
        borderRadius: "38px",
        backgroundColor: "#000",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#343434",
        },
    },
    removeButton: {
        textTransform: "none",
        borderRadius: "38px",
        backgroundColor: "#000",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#343434",
        },
    },
}))

// Optional Additional Information fields
const optionalInfoKeys = [
    "Company Address",
    "Website",
    "Nature of Business",
    "Email",
    "Phone Number",
    "Power of Attorney",
    "Beneficial Ownership Structure",
    "Directors",
    "High-Risk Indication",
]

const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Côte d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Democratic People's Republic of Korea",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia (Federated States of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Republic of Korea",
    "Republic of Moldova",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Tajikistan",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United Republic of Tanzania",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
]

const CaseInformationMainPage = ({
    setValue,
    control,
    setUploadingFile,
    close,
    resetForm,
    getValues,
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: "Additional Information",
    })

    const [rawMode, setRawMode] = useState(false)
    const [rawJson, setRawJson] = useState("")
    const [isValidJson, setIsValidJson] = useState(true)

    // Reset the form on closure
    useEffect(() => {
        if (close) {
            resetForm({
                "Company Name": "",
                Country: "",
                "Registration Number": "",
                "Additional Information": [],
            })
            setRawMode(false)
            setRawJson("")
            setIsValidJson(true)
        }
    }, [close, resetForm])

    const handleFileUpload = async (single_file_list) => {
        try {
            setUploadingFile && setUploadingFile(true)
            var file = single_file_list[0]
            const response = await uploadToS3(
                "case_list",
                file,
                `case_list_${uuidv4().slice(0, 8)}` + file.name.slice(file.name.indexOf(".")),
                false,
                dispatch,
                refreshAccessTokenCounter,
            )
            const file_address = response.config.url.slice(0, response.config.url.indexOf("?"))
            setValue("template_fields.case_list_file", file_address)
        } finally {
            setUploadingFile && setUploadingFile(false)
        }
    }

    // Build JSON from current values in form
    const fieldsToJson = () => {
        const formFields = getValues("Additional Information") || []
        const obj = {}
        formFields.forEach((f) => {
            const fieldKey = f.key || ""
            obj[fieldKey] = f.value || ""
        })
        return JSON.stringify(obj, null, 2)
    }

    // Parse JSON and build fields array
    const jsonToFields = (jsonString) => {
        try {
            const obj = JSON.parse(jsonString)
            const newFields = Object.keys(obj).map((key) => ({
                key: key,
                value: obj[key],
            }))
            return newFields
        } catch (e) {
            return null
        }
    }

    const handleRawModeChange = (event) => {
        const nextMode = event.target.checked
        if (nextMode) {
            // Switching to raw mode
            const jsonStr = fieldsToJson()
            setRawJson(jsonStr)
            setIsValidJson(true)
            setRawMode(true)
        } else {
            // Switching back to field mode
            const parsedFields = jsonToFields(rawJson)
            if (parsedFields === null) {
                // Invalid JSON, can't switch back
                setIsValidJson(false)
                return
            }
            // Valid JSON
            replace(parsedFields)
            setRawMode(false)
        }
    }

    const handleRawJsonChange = (e) => {
        const val = e.target.value
        setRawJson(val)
        // Validate JSON
        try {
            JSON.parse(val)
            setIsValidJson(true)
        } catch {
            setIsValidJson(false)
        }
    }

    return (
        <Box
            className="mainPageContainer"
            sx={{
                background: "#f6f7fc",
                padding: { xs: "28px 0px", md: "28px 64px" },
                borderRadius: "10px",
                width: "100%",
                // Apply scroll to the entire data section
                // maxHeight: "500px",
                overflowY: "auto",
            }}
        >
            <Accordion
                defaultExpanded
                style={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: "0",
                }}
            >
                {/* Make the AccordionDetails as part of the scroll container (no separate scroll for Additional Info) */}
                <AccordionDetails sx={{ p: 0 }}>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "62.5px 62.5px repeat(10, 1fr)",
                            gap: "8px",
                            rowGap: "20px",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        {/* Company Name */}
                        <Box sx={{ gridColumn: "1/3" }}>
                            <Typography component="label" variant="body1" color="#3E3E3E">
                                Company Name:
                            </Typography>
                        </Box>
                        <Box sx={{ gridColumn: "3/13" }}>
                            <Controller
                                name="Company Name"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        id="Company Name"
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                )}
                            />
                        </Box>

                        {/* Country (Autocomplete) */}
                        <Box sx={{ gridColumn: "1/3" }}>
                            <Typography component="label" variant="body1" color="#3E3E3E">
                                Country:
                            </Typography>
                        </Box>
                        <Box sx={{ gridColumn: "3/13" }}>
                            <Controller
                                name="Country"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        options={countries}
                                        getOptionLabel={(option) => option}
                                        value={field.value || ""}
                                        onChange={(_, newValue) => field.onChange(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                id="Country"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    className: classes.input,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                placeholder="Type or choose a country"
                                                fullWidth
                                            />
                                        )}
                                        freeSolo
                                    />
                                )}
                            />
                        </Box>

                        {/* Registration Number */}
                        <Box sx={{ gridColumn: "1/3" }}>
                            <Typography component="label" variant="body1" color="#3E3E3E">
                                Registration Number:
                            </Typography>
                        </Box>
                        <Box sx={{ gridColumn: "3/13" }}>
                            <Controller
                                name="Registration Number"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        id="Registration Number"
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                )}
                            />
                        </Box>

                        {/* Additional Information */}
                        <Box sx={{ gridColumn: "1/3", display: "flex", alignItems: "center" }}>
                            <Typography component="label" variant="body1" color="#3E3E3E">
                                Additional Information:
                            </Typography>
                        </Box>
                        <Box sx={{ gridColumn: "3/13" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "20px",
                                    backgroundColor: "#fff",
                                    padding: "20px",
                                    borderRadius: "10px",
                                    // Removed scroll here, so it will be part of the main container's scroll
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                        <Typography variant="body1" color="#3E3E3E">
                                            View as Raw JSON
                                        </Typography>
                                        <Switch
                                            checked={rawMode}
                                            onChange={handleRawModeChange}
                                            color="default"
                                        />
                                        {rawMode &&
                                            (isValidJson ? (
                                                <Tooltip title="JSON is valid">
                                                    <CheckCircleOutlineIcon
                                                        sx={{ color: "green" }}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Invalid JSON format">
                                                    <ErrorOutlineIcon sx={{ color: "red" }} />
                                                </Tooltip>
                                            ))}
                                    </Box>
                                </Box>

                                {rawMode ? (
                                    <TextField
                                        value={rawJson}
                                        onChange={handleRawJsonChange}
                                        variant="outlined"
                                        multiline
                                        rows={6}
                                        InputProps={{
                                            className: classes.textArea,
                                            style: { fontFamily: "monospace" },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                ) : (
                                    <>
                                        {fields.map((item, index) => (
                                            <Box
                                                key={item.id}
                                                sx={{
                                                    display: "grid",
                                                    gridTemplateColumns: "150px 1fr auto",
                                                    gap: "10px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Controller
                                                    name={`Additional Information.${index}.key`}
                                                    control={control}
                                                    defaultValue={item.key || ""}
                                                    render={({
                                                        field: { onChange, onBlur, value },
                                                    }) => (
                                                        <Autocomplete
                                                            freeSolo
                                                            options={optionalInfoKeys}
                                                            value={value}
                                                            onChange={(e, newVal) =>
                                                                onChange(newVal)
                                                            }
                                                            onInputChange={(e, newVal) =>
                                                                onChange(newVal)
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    label="Field Name"
                                                                    onBlur={onBlur}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        className: classes.input,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    fullWidth
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    name={`Additional Information.${index}.value`}
                                                    control={control}
                                                    defaultValue={item.value || ""}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            variant="outlined"
                                                            label="Value"
                                                            multiline
                                                            rows={1}
                                                            InputProps={{
                                                                className: classes.input,
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                                <Button
                                                    variant="contained"
                                                    onClick={() => remove(index)}
                                                    className={classes.removeButton}
                                                    sx={{ height: "40px", whiteSpace: "nowrap" }}
                                                >
                                                    Remove
                                                </Button>
                                            </Box>
                                        ))}
                                        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                                            <Button
                                                variant="contained"
                                                onClick={() => append({ key: "", value: "" })}
                                                className={classes.addButton}
                                                sx={{ height: "40px", whiteSpace: "nowrap" }}
                                            >
                                                Add Field
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default CaseInformationMainPage
